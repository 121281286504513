import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Emergency!
    </Text>
    <p>{`本页面用于发布我的紧急求助/通知信息，通常意味着我无法通过其他正常渠道发布状态更新。`}</p>
    <h3><a parentName="h3" {...{
        "href": "/14636"
      }}>{`146:36 主眼镜损坏`}</a></h3>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      